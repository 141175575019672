import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import React, { useContext } from "react";
import { useRouter } from "next/router";
import Lottie from "lottie-react-web";
import ProjectContext from "../../providers/ProjectDashboardContext";
import AppContext from "../../providers/AppContext";
import animationData from "./24843-credit-cards.json";
import Flex from "../common/Flex";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export interface BillingQuotaExceededModalProps {
  isOpen: boolean;
  onContinueClick: () => void;
  onViewMyLimitsClick: () => void;
  toggle: () => void;
}

export const BillingQuotaExceededModal: React.FC<BillingQuotaExceededModalProps> = ({
  isOpen,
  onContinueClick,
  onViewMyLimitsClick,
  toggle,
}) => (
  <Modal isOpen={isOpen} centered toggle={toggle}>
    <ModalBody className="text-center">
      <div className="chromatic-ignore">
        <Lottie options={lottieOptions} style={{ width: 250 }} />
      </div>
      <h4 className="mb-3">Upgrade your plan to continue</h4>
      <p>
        Your billing limit for this action has been exceeded. To perform this
        action, you must upgrade your plan.
      </p>
      <p>
        <Button color="link" onClick={onViewMyLimitsClick}>
          View my limits
        </Button>
      </p>
      <p>If you have any questions, please contact us.</p>
    </ModalBody>
    <ModalFooter
      tag={Flex}
      justify="between"
      align="center"
      className="bg-light border-top-0"
    >
      <Button color="link" onClick={toggle}>
        Cancel
      </Button>
      <Button color="success" className="px-5" onClick={onContinueClick}>
        Upgrade my plan
      </Button>
    </ModalFooter>
  </Modal>
);

const BillingQuotaExceededModalContainer: React.FC = () => {
  const router = useRouter();
  const { projectId } = useContext(ProjectContext);
  const {
    showBillingQuotaExceededModal,
    setShowBillingQuotaExceededModal,
  } = useContext(AppContext);

  const handleOnContinueClick = () => {
    setShowBillingQuotaExceededModal(false);
    router.push(`/projects/${projectId}/billing/plans`).catch(() => {});
  };

  const handleOnViewMyLimitsClick = () => {
    setShowBillingQuotaExceededModal(false);
    router.push(`/projects/${projectId}/billing`).catch(() => {});
  };

  const toggle = () => {
    setShowBillingQuotaExceededModal((prevState) => !prevState);
  };

  return (
    <BillingQuotaExceededModal
      isOpen={showBillingQuotaExceededModal}
      onContinueClick={handleOnContinueClick}
      onViewMyLimitsClick={handleOnViewMyLimitsClick}
      toggle={toggle}
    />
  );
};

export default BillingQuotaExceededModalContainer;
