import React from "react";
import * as Sentry from "@sentry/react";
import { useUser } from "../../api/users";

const SentryProvider: React.FC = ({ children }) => {
  const { user, isAuthenticated } = useUser();
  Sentry.configureScope((scope) => {
    if (isAuthenticated) {
      const { sub: id, email } = user;
      scope.setUser({ id, email });
    } else {
      scope.setUser(null);
    }
  });

  return <>{children}</>;
};

export default SentryProvider;
