import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import React, { useContext } from "react";
import { useRouter } from "next/router";
import Lottie from "lottie-react-web";
import ProjectContext from "../../providers/ProjectDashboardContext";
import AppContext from "../../providers/AppContext";
import animationData from "./24843-credit-cards.json";
import Flex from "../common/Flex";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export interface BillingInactiveModalProps {
  isOpen: boolean;
  onClick: () => void;
}

export const BillingInactiveModal: React.FC<BillingInactiveModalProps> = ({
  isOpen,
  onClick,
}) => (
  <Modal isOpen={isOpen} centered>
    <ModalBody className="text-center">
      <div className="chromatic-ignore">
        <Lottie options={lottieOptions} style={{ width: 250 }} />
      </div>
      <h4 className="mb-3">Activate your billing to continue</h4>
      <p>
        It looks like you don&apos;t have active billing in your account. To
        continue using Talar, please choose your plan and make a payment.
      </p>
      <p>If you have any questions, please contact us.</p>
    </ModalBody>
    <ModalFooter
      tag={Flex}
      justify="center"
      align="center"
      className="bg-light border-top-0"
    >
      <Button color="success" className="px-5" onClick={onClick}>
        Activate billing
      </Button>
    </ModalFooter>
  </Modal>
);

const BillingInactiveModalContainer: React.FC = () => {
  const router = useRouter();
  const { projectId } = useContext(ProjectContext);
  const { showBillingInactiveModal, setShowBillingInactiveModal } = useContext(
    AppContext
  );

  const handleOnClick = () => {
    setShowBillingInactiveModal(false);
    router.push(`/projects/${projectId}/billing/plans`).catch(() => {});
  };

  return (
    <BillingInactiveModal
      isOpen={showBillingInactiveModal}
      onClick={handleOnClick}
    />
  );
};

export default BillingInactiveModalContainer;
