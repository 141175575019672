import React, { useEffect, useState } from "react";
import { NextPage } from "next";
import { AppProps } from "next/app";
import { Auth0Provider } from "@auth0/auth0-react";
import { IntlProvider } from "react-intl";
import detectBrowserLanguage from "detect-browser-language";
import ApiProvider from "../providers/ApiProvider";
import DashboardProvider from "../providers/DashboardProvider";
import AppProvider from "../providers/AppProvider";
import "../initializers/fontawesome";
import "../initializers/sentry";
import gtm from "../initializers/gtm";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "../styles/main.scss";
import SentryProvider from "../components/sentry/Sentry";
import PageLayout from "../layouts/PageLayout";

const App: NextPage<AppProps> = ({ Component, pageProps }) => {
  const [locale, setLocale] = useState("en");
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const lang = detectBrowserLanguage() as string;
    setLocale(lang);
  }, []);

  useEffect(() => {
    gtm();
  }, []);

  return (
    <AppProvider>
      <IntlProvider locale={locale}>
        <Auth0Provider
          domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN}
          clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
          redirectUri={process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URI}
          audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
          useRefreshTokens
        >
          <SentryProvider>
            <ApiProvider>
              <DashboardProvider>
                <PageLayout>
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <Component {...pageProps} />
                </PageLayout>
              </DashboardProvider>
            </ApiProvider>
          </SentryProvider>
        </Auth0Provider>
      </IntlProvider>
    </AppProvider>
  );
};

export default App;
