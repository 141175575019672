import { createContext } from "react";

interface Context {
  id?: string;
  name: string;
  isLoading: boolean;
}

const OrganizationDashboardContext = createContext<Partial<Context>>({});

export default OrganizationDashboardContext;
