import { createContext } from "react";

interface Context {
  showBillingInactiveModal: boolean;
  setShowBillingInactiveModal: (boolean) => void;
  showBillingQuotaExceededModal: boolean;
  setShowBillingQuotaExceededModal: (boolean) => void;
}

export const defaultValue = {
  showBillingInactiveModal: false,
  showBillingQuotaExceededModal: false,
};

const AppContext = createContext<Partial<Context>>(defaultValue);

export default AppContext;
