import { ParsedUrlQuery } from "querystring";
import { useContext } from "react";
import { UrlObject } from "url";
import ProjectContext from "../providers/ProjectDashboardContext";

export const getFirstQueryParm = (
  query: ParsedUrlQuery,
  key: string
): string => {
  const { [key]: param } = query;
  return Array.isArray(param) ? param[0] : param;
};

export const useProjectUrl = (
  href: string | UrlObject,
  as: string | UrlObject
): { href: string; as: string } => {
  const { projectId } = useContext(ProjectContext);
  return {
    href: `/projects/[projectId]${href.toString()}`,
    as: `/projects/${projectId}${as.toString()}`,
  };
};
