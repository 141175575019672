import React from "react";
import OrganizationDashboardContext from "./OrganizationDashboardContext";
import { useOrganization } from "../api/organizations";

const OrganizationDashboardProvider: React.FC<{ organizationId: string }> = ({
  organizationId,
  children,
}) => {
  const { data, loading } = useOrganization(organizationId);
  const { name } = data || {};

  const value = {
    id: organizationId,
    name,
    isLoading: loading,
  };

  return (
    <OrganizationDashboardContext.Provider value={value}>
      {children}
    </OrganizationDashboardContext.Provider>
  );
};

export default OrganizationDashboardProvider;
