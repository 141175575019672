/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  MutateMethod,
  useGet,
  UseGetReturn,
  useMutate,
  UseMutateReturn,
} from "restful-react";

export type OrganizationResponseData = {
  id: string;
  name: string;
};

export type OrganizationRequestBody = {
  name: string;
};

interface OrganizationPostResponse
  extends UseMutateReturn<any, any, any, any, any> {
  mutate: MutateMethod<
    OrganizationResponseData,
    OrganizationRequestBody,
    any,
    any
  >;
}

export const useCreateOrganization = (): OrganizationPostResponse =>
  useMutate({
    verb: "POST",
    path: "organizations/",
  });

interface OrganizationGetResponse extends UseGetReturn<any, any> {
  data: OrganizationResponseData;
}

export const useOrganization = (
  organizationId: string
): OrganizationGetResponse =>
  useGet({
    path: `organizations/${organizationId}/`,
    lazy: !organizationId,
  });

interface OrganizationsGetResponse extends UseGetReturn<any, any> {
  data: OrganizationResponseData[];
}

export const useOrganizations = (): OrganizationsGetResponse =>
  useGet({
    path: "organizations/",
  });

export const useUpdateOrganization = (
  organizationId: string
): OrganizationPostResponse =>
  useMutate({
    verb: "PUT",
    path: `organizations/${organizationId}/`,
  });
