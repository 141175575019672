import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Head from "next/head";
import BillingInactiveModalContainer from "../components/billing/BillingInactiveModal";
import BillingQuotaExceededModalContainer from "../components/billing/BillingQuotaExceededModal";

const Page: React.FC = ({ children }) => (
  <>
    <Head>
      <title>Talar</title>
      <link rel="icon" href="/favicon.ico" />
    </Head>
    {children}
    <BillingInactiveModalContainer />
    <BillingQuotaExceededModalContainer />
  </>
);

export default withAuthenticationRequired(Page);
