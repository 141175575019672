import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPoll,
  faSearch,
  faCircleNotch,
  faWallet,
  faChevronLeft,
  faChevronRight,
  faCaretDown,
  faSyncAlt,
  faPlug,
  faEye,
  faEyeSlash,
  faPlus,
  faKey,
  faEllipsisH,
  faExternalLinkAlt,
  faQuestion,
  faCog,
  faAtom,
  faUsers,
  faCreditCard,
  faCheck,
  faLock,
  faPaperPlane,
  faPencilAlt,
  faAngleDown,
  faAngleDoubleDown,
  faAngleUp,
  faAngleDoubleUp,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircle as farCircle,
  faDotCircle as farDotCircle,
  faTimesCircle as farTimesCircle,
  faCheckCircle as farCheckCircle,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faPoll,
  faSearch,
  faCircleNotch,
  faWallet,
  farCheckCircle,
  farCircle,
  farDotCircle,
  farTimesCircle,
  faChevronLeft,
  faChevronRight,
  faCaretDown,
  faSyncAlt,
  faPlug,
  faEye,
  faEyeSlash,
  faPlus,
  faKey,
  faEllipsisH,
  faExternalLinkAlt,
  faQuestion,
  faCog,
  faAtom,
  faUsers,
  faCreditCard,
  faCheck,
  faLock,
  faPaperPlane,
  faPencilAlt,
  faAngleDown,
  faAngleDoubleDown,
  faAngleUp,
  faAngleDoubleUp
);
