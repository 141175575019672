import React, { useState } from "react";
import AppContext, { defaultValue } from "./AppContext";

const AppProvider: React.FC = ({ children }) => {
  const [showBillingInactiveModal, setShowBillingInactiveModal] = useState(
    defaultValue.showBillingInactiveModal
  );
  const [
    showBillingQuotaExceededModal,
    setShowBillingQuotaExceededModal,
  ] = useState(defaultValue.showBillingQuotaExceededModal);

  const value = {
    showBillingInactiveModal,
    setShowBillingInactiveModal,
    showBillingQuotaExceededModal,
    setShowBillingQuotaExceededModal,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
