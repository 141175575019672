import React, { useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { RestfulProvider } from "restful-react";
import AppContext from "./AppContext";

interface ApiProviderProps {
  base?: string;
}

type BackendError = { message: string; code: string };

const ApiProvider: React.FC<ApiProviderProps> = ({ base, children }) => {
  const { getAccessTokenSilently, loginWithPopup } = useAuth0();
  const {
    setShowBillingInactiveModal,
    setShowBillingQuotaExceededModal,
  } = useContext(AppContext);

  return (
    <RestfulProvider
      base={base}
      requestOptions={() => {
        try {
          return getAccessTokenSilently().then((token) => ({
            headers: { Authorization: `Bearer ${token}` },
          }));
        } catch (e) {
          return loginWithPopup().then();
        }
      }}
      onError={(err) => {
        const { status, data } = err;
        if (status === 403) {
          const { code } = data as BackendError;
          if (code === "billing_inactive") {
            setShowBillingInactiveModal(true);
          } else if (code === "billing_quota_exceeded") {
            setShowBillingQuotaExceededModal(true);
          }
        }
      }}
    >
      {children}
    </RestfulProvider>
  );
};

ApiProvider.defaultProps = {
  base: process.env.NEXT_PUBLIC_TALAR_API_URL,
};

export default ApiProvider;
