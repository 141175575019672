import React from "react";
import { useRouter } from "next/router";
import { getFirstQueryParm } from "../helpers/router";
import OrganizationDashboardProvider from "./OrganizationDashboardProvider";
import ProjectDashboardProvider from "./ProjectDashboardProvider";

const DashboardProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const projectId = getFirstQueryParm(router.query, "projectId");
  const organizationId = getFirstQueryParm(router.query, "organizationId");

  if (projectId) {
    return (
      <ProjectDashboardProvider projectId={projectId}>
        {children}
      </ProjectDashboardProvider>
    );
  }

  if (organizationId) {
    return (
      <OrganizationDashboardProvider organizationId={organizationId}>
        {children}
      </OrganizationDashboardProvider>
    );
  }

  return <>{children}</>;
};

export default DashboardProvider;
