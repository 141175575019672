import React from "react";
import { useProject } from "../api/projects";
import ProjectDashboardContext from "./ProjectDashboardContext";
import { useOrganization } from "../api/organizations";

const ProjectDashboardProvider: React.FC<{ projectId: string }> = ({
  projectId,
  children,
}) => {
  const { data: projectData, loading: projectLoading } = useProject(projectId);
  const { data: organization, loading: organizationLoading } = useOrganization(
    projectData && projectData.organization
  );

  const value = {
    projectId,
    projectData,
    organization,
    isLoading: !!projectLoading || !!organizationLoading,
  };

  return (
    <ProjectDashboardContext.Provider value={value}>
      {children}
    </ProjectDashboardContext.Provider>
  );
};

export default ProjectDashboardProvider;
